<template>
  <ContentCard :loading="loading" :progress="uploadProgress">
    <template #header>
      <ReviewsProductTabs />
    </template>

    <template #subheader>
      <div class="my-6 flex flex-row justify-between items-center">
        <h2 class="text-headline-m">{{ t('Reviews.product.view.detailsTitle', { productName: product.title }) }}</h2>
        <div>
          <RouterLink class="btn-secondary-purple-m mr-2" :to="{ name: RouteName.ReviewsProductView, params: { productId: product.id } }">
            {{ t('Reviews.product.action.cancel') }}
          </RouterLink>
          <button class="btn-primary-purple-m" @click="onSubmit">{{ t('Reviews.product.action.save') }}</button>
        </div>
      </div>
    </template>

    <div class="pt-6">
      <div class="grid grid-cols-4">
        <LabelValue :label="t('Reviews.product.view.logo.label').toString()">
          <MediaUpload
            :accept="LOGO_MIME_TYPES.join(', ')"
            :preview-asset="product.logo || undefined"
            :hint="t('Reviews.product.view.logo.hint').toString()"
            :short-hint="shortLogoHint"
            :error="getValidationErrors(v$.logo)"
            @upload="handleLogoUpload"
          >
            <template #preview="previewProps">
              <ProductLogo :logo-url="previewProps.url" size="large" />
            </template>
          </MediaUpload>
        </LabelValue>
      </div>
      <LabelValue :label="t('Reviews.product.view.hero.label').toString()">
        <MediaUpload
          :accept="HERO_MIME_TYPES.join(', ')"
          aspect-ratio="16:9"
          :disabled="!product.heroActive"
          max-width="648px"
          :preview-asset="product.hero || undefined"
          :hint="heroHint"
          :short-hint="shortHeroHint"
          :error="getValidationErrors(v$.hero)"
          @upload="handleHeroUpload"
        />
      </LabelValue>
      <LabelValue :label="t('Reviews.product.view.description.label').toString()">
        <div class="flex gap-x-4">
          <TextInput
            v-for="lang in ALLOWED_LOCALES"
            :key="lang"
            v-model="v$.shortDescription[lang].$model"
            :error="getValidationErrors(v$.shortDescription[lang])"
            :label="t('Reviews.product.view.shortDescription.label').toString()"
            :max-length="SHORT_DESCRIPTION_MAX_LENGTH"
            :rows="3"
            type="textarea"
            @keydown.enter.prevent
          >
            <template #label>
              <div class="flex justify-between items-end">
                <span>{{ t('Reviews.product.view.shortDescription.label') }}</span>
                <ChipBadge class="uppercase" rounded>{{ lang }}</ChipBadge>
              </div>
            </template>
          </TextInput>
        </div>
        <div class="flex gap-x-4">
          <MarkdownInput
            v-for="lang in ALLOWED_LOCALES"
            :key="lang"
            v-model="v$.description[lang].$model"
            class="w-1/2"
            dense
            :allowed-tags="['h3', 'h4', 'h5', 'p']"
          />
        </div>
      </LabelValue>
      <LabelValue :label="t('Reviews.product.view.links.label').toString()">
        <div class="mt-2 mb-6 text-grey-800">
          <MdiSvg class="inline-block mr-2 text-purple-600" :path="mdiInformationOutline" />{{ t('Reviews.product.edit.url.hint') }}
        </div>
        <div class="grid grid-cols-2 gap-x-6">
          <div v-for="lang in ALLOWED_LOCALES" :key="lang" class="flex items-center">
            <MdiSvg :path="mdiWeb" :size="32" class="mr-2 text-grey-800" />
            <TextInput v-model="v$.url[lang].$model" :error="getValidationErrors(v$.url[lang])" type="url">
              <template #label>
                <div class="flex justify-between items-end">
                  <span>Website</span>
                  <ChipBadge class="uppercase" rounded>{{ lang }}</ChipBadge>
                </div>
              </template>
            </TextInput>
          </div>
          <div v-for="lang in ALLOWED_LOCALES" :key="lang" class="flex items-center">
            <MdiSvg :path="mdiFormatTitle" :size="32" class="mr-2 text-grey-800" />
            <TextInput
              v-model="v$.linkText[lang].$model"
              :error="getValidationErrors(v$.linkText[lang])"
              label="Website Link Text"
              placeholder="Visit omr.com"
              type="url"
            >
              <template #label>
                <div class="flex justify-between items-end">
                  <span>Website Link Text</span>
                  <ChipBadge class="uppercase" rounded>{{ lang }}</ChipBadge>
                </div>
              </template>
            </TextInput>
          </div>
          <div class="flex items-center">
            <MdiSvg :path="mdiLinkedin" :size="32" class="mr-2 text-grey-800" />
            <TextInput v-model="v$.linkedin.$model" :error="getValidationErrors(v$.linkedin)" label="LinkedIn" type="url" />
          </div>
          <div />
          <div class="flex items-center">
            <MdiSvg :path="iconTwitterX" :size="32" class="mr-2 text-grey-800" />
            <TextInput v-model="v$.twitter.$model" :error="getValidationErrors(v$.twitter)" label="X" type="url" />
          </div>
        </div>
      </LabelValue>
      <ReviewsProductCtaEdit :product="product" @change="handleCtaUpdate" />
    </div>
    <template #footer>
      <div class="my-6 flex flex-row justify-end items-center">
        <RouterLink class="btn-secondary-purple-m mr-2" :to="{ name: RouteName.ReviewsProductView, params: { productId: product.id } }">
          {{ t('Reviews.product.action.cancel') }}
        </RouterLink>
        <button class="btn-primary-purple-m" @click="onSubmit">{{ t('Reviews.product.action.save') }}</button>
      </div>
    </template>
  </ContentCard>
</template>

<script setup lang="ts">
import { computed, inject, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ChipBadge, MdiSvg, MarkdownInput, TextInput, iconTwitterX } from '@ramp106/omrjs-core-ui'
import { ProductLogo } from '@ramp106/omrjs-reviews-ui'
import TurnDown from 'turndown'
import { mdiFormatTitle, mdiInformationOutline, mdiLinkedin, mdiWeb } from '@mdi/js'
import { type ProductAttributes, type ProductData, type TranslationAttributes } from '@/gql/reviews'
import { useI18n } from 'vue-i18n'
import LabelValue from '@/ui/LabelValue.vue'
import ContentCard from '@/ui/ContentCard.vue'
import MediaUpload from '@/ui/MediaUpload.vue'
import ReviewsProductTabs from '../ReviewsProductTabs.vue'
import useVuelidate, { type ValidationArgs } from '@vuelidate/core'
import {
  buildTranslationFieldsRules,
  fileMaxSizeRule,
  fileMimeTypeRule,
  getValidationErrors,
  imageMaxDimensionRule,
  imageMinDimensionRule,
  urlRule,
} from '@/helpers/validationHelper'
import { maxLength } from '@/services/validators'
import { useUpdateReviewsProductMutation } from '@/gql/reviews'
import { showNotification } from '@/helpers/notificationHelper'
import { reloadReviewsProductKey } from '@/symbols/reloadReviewsProductKey'
import type { ReviewsProductEditFormData } from '../types'
import { RouteName } from '@/router/types'
import ReviewsProductCtaEdit from './ReviewsProductCtaEdit.vue'
import type { ProductCtaFormData } from '../types'
import { ALLOWED_LOCALES } from '@/helpers/translationsHelper'

const SHORT_DESCRIPTION_MAX_LENGTH = 180
const props = defineProps<{
  product: ProductData
}>()

const { t } = useI18n()
const router = useRouter()

const uploadProgress = ref(0)
const reloadReviewsProduct = inject(reloadReviewsProductKey) as () => void

const formData = reactive<ReviewsProductEditFormData>({
  description: { ...props.product.descriptionTranslationsMap },
  shortDescription: { ...props.product.shortDescriptionTranslationsMap },
  url: { ...props.product.urlTranslationsMap },
  linkText: { ...props.product.linkTextTranslationsMap },
  linkedin: props.product.linkedin || null,
  twitter: props.product.twitter || null,
  logo: null,
  hero: null,
  ctaPrimaryHide: props.product.ctaPrimaryHide,
  ctaSecondaryLabel: { ...props.product.ctaSecondaryLabelTranslationsMap },
  ctaSecondaryUrl: { ...props.product.ctaSecondaryUrlTranslationsMap },
})

const LOGO_MAX_SIZE_MB = 5
const LOGO_MIME_TYPES = ['image/jpeg', 'image/png', 'image/webp', 'image/gif']
const HERO_MAX_SIZE_MB = 50
const HERO_MIME_TYPES = ['image/jpeg', 'image/png', 'image/webp', 'image/gif', 'video/mp4', 'video/webm']

const shortLogoHint = computed(() => {
  const acceptedMimeTypes = LOGO_MIME_TYPES.map((type) => `${type.split('/')[1]}`).join(', ')

  return [acceptedMimeTypes, `Max. ${LOGO_MIME_TYPES} MB`]
})

const heroHint = computed(() =>
  props.product.heroActive ? t('Reviews.product.view.hero.hint').toString() : t('Reviews.product.view.hero.hintDisabled').toString(),
)

const shortHeroHint = computed(() => {
  const acceptedMimeTypes = HERO_MIME_TYPES.map((type) => `${type.split('/')[1]}`).join(', ')

  return props.product.heroActive
    ? [acceptedMimeTypes, `Max. ${HERO_MAX_SIZE_MB} MB`]
    : t('Reviews.product.view.hero.shortHintDisabled').toString()
})

const rules = computed<ValidationArgs<ReviewsProductEditFormData>>(() => ({
  logo: {
    fileSize: fileMaxSizeRule(t, { megaBytes: LOGO_MAX_SIZE_MB }),
    fileType: fileMimeTypeRule(t, { mimeTypes: LOGO_MIME_TYPES }),
    minDimension: imageMinDimensionRule(t, { minWidth: 64, minHeight: 64 }),
    maxDimension: imageMaxDimensionRule(t, { maxWidth: 1024, maxHeight: 1024 }),
  },
  hero: {
    fileSize: fileMaxSizeRule(t, { megaBytes: HERO_MAX_SIZE_MB }),
    fileType: fileMimeTypeRule(t, { mimeTypes: HERO_MIME_TYPES }),
    minDimension: imageMinDimensionRule(t, { minWidth: 646, minHeight: 364 }),
  },
  description: buildTranslationFieldsRules({}),
  shortDescription: buildTranslationFieldsRules({ maxLength: maxLength(SHORT_DESCRIPTION_MAX_LENGTH) }),
  linkText: buildTranslationFieldsRules({}),
  url: buildTranslationFieldsRules({ urlFormat: urlRule(t) }),
  linkedin: { urlFormat: urlRule(t) },
  twitter: { urlFormat: urlRule(t) },
  ctaPrimaryHide: {},
  ctaSecondaryLabel: {},
  ctaSecondaryUrl: {},
}))

const v$ = useVuelidate(rules, formData)

function handleLogoUpload(file: File) {
  v$.value.logo.$model = file
}

function handleHeroUpload(file: File) {
  v$.value.hero.$model = file
}

const {
  mutate: mutateProduct,
  loading: loadingUpdateProduct,
  onDone,
  onError,
} = useUpdateReviewsProductMutation({
  clientId: 'reviews',
  context: {
    fetchOptions: {
      useUpload: true,
      onProgress: (progress: ProgressEvent) => {
        uploadProgress.value = Math.round((progress.loaded / progress.total) * 100)
      },
    },
  },
})

const loading = computed(() => loadingUpdateProduct.value)

const handleCtaUpdate = (data: ProductCtaFormData) => {
  formData.ctaPrimaryHide = data.ctaPrimaryHide
  formData.ctaSecondaryUrl = { ...data.ctaSecondaryUrl }
  formData.ctaSecondaryLabel = { ...data.ctaSecondaryLabel }
}

async function onSubmit() {
  v$.value.$touch()
  if (v$.value.$invalid) {
    showNotification(t('Reviews.product.notifications.invalidData'), 'error')
    return
  }

  try {
    const uploadedHeroImage = formData.hero && formData.hero.type.startsWith('image')
    const uploadedHeroVideo = formData.hero && formData.hero.type.startsWith('video')

    let heroImage
    let heroVideo
    if (uploadedHeroImage) {
      heroImage = formData.hero
      heroVideo = null
    }
    if (uploadedHeroVideo) {
      heroVideo = formData.hero
      heroImage = null
    }

    const turnDown = new TurnDown({
      emDelimiter: '_',
      linkStyle: 'inlined',
      headingStyle: 'atx',
    })

    const descriptionTranslations: TranslationAttributes[] = []
    const shortDescriptionTranslations: TranslationAttributes[] = []
    const linkTextTranslations: TranslationAttributes[] = []
    const urlTranslations: TranslationAttributes[] = []

    ALLOWED_LOCALES.forEach((locale) => {
      if (
        v$.value.description[locale]?.$dirty &&
        v$.value.description[locale].$model !== props.product.descriptionTranslationsMap[locale]
      ) {
        descriptionTranslations.push({
          locale,
          value: formData.description[locale] ? turnDown.turndown(formData.description[locale]) : '',
        })
      }

      shortDescriptionTranslations.push({
        locale,
        value: formData.shortDescription[locale],
      })

      linkTextTranslations.push({
        locale,
        value: formData.linkText[locale],
      })

      urlTranslations.push({
        locale,
        value: formData.url[locale],
      })
    })

    const attributes: ProductAttributes = {
      logo: formData.logo ? formData.logo : undefined,
      heroImage,
      heroVideo,
      descriptionTranslations,
      shortDescriptionTranslations,
      urlTranslations,
      linkTextTranslations,
      linkedin: formData.linkedin,
      twitter: formData.twitter,
      ctaPrimaryHide: formData.ctaPrimaryHide,
    }

    if (props.product.ctaSecondaryEditable) {
      const ctaSecondaryLabelTranslations: TranslationAttributes[] = []
      const ctaSecondaryUrlTranslations: TranslationAttributes[] = []
      ALLOWED_LOCALES.forEach((locale) => {
        ctaSecondaryLabelTranslations.push({
          locale,
          value: formData.ctaSecondaryLabel[locale],
        })
        ctaSecondaryUrlTranslations.push({
          locale,
          value: formData.ctaSecondaryUrl[locale],
        })
      })
      attributes.ctaSecondaryLabelTranslations = ctaSecondaryLabelTranslations
      attributes.ctaSecondaryUrlTranslations = ctaSecondaryUrlTranslations
    }

    await mutateProduct({
      id: props.product.id,
      attributes,
    })
  } catch (e) {
    uploadProgress.value = 0
  }
}

onDone(() => {
  uploadProgress.value = 0
  reloadReviewsProduct()
  showNotification(t('Reviews.product.notifications.savedSuccessfully', { name: props.product.title }), 'success')
  router.push({ name: RouteName.ReviewsProductView, params: { productId: props.product.id } })
})

onError((error) => showError(error))

const showError = (error: any) => {
  uploadProgress.value = 0
  if (error) showNotification(error.message, 'error')
}
</script>
