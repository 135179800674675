<template>
  <ContentCard :loading="loading" :progress="uploadProgress">
    <template #header>
      <ReviewsProductTabs />
    </template>
    <template #subheader>
      <div class="my-6 flex flex-row justify-between items-center">
        <h2 class="text-headline-m">{{ t('Reviews.product.edit.documentsTitle', { productName: product.title }) }}</h2>
        <div>
          <RouterLink
            class="btn-secondary-purple-m mr-2"
            :to="{ name: RouteName.ReviewsProductViewAssets, params: { productId: product.id } }"
          >
            {{ t('Reviews.product.action.cancel') }}
          </RouterLink>
          <button class="btn-primary-purple-m" @click="onSubmit">{{ t('Reviews.product.action.save') }}</button>
        </div>
      </div>
    </template>
    <div class="py-6">
      <LabelValue :label="t('Reviews.product.view.screenshots.label').toString()">
        <ReviewsProductScreenshotsEdit :screenshots="product.screenshots || []" @change="handleScreenshotsUpdate" />
      </LabelValue>
      <LabelValue :label="t('Reviews.product.view.documents.label').toString()">
        <ReviewsProductDocumentsEditForm :documents="product.documents" @change="handleDocumentsUpdate" />
      </LabelValue>
    </div>
  </ContentCard>
</template>

<script setup lang="ts">
import {
  type ProductData,
  useCreateReviewsProductDocumentMutation,
  useDeleteReviewsProductDocumentsMutation,
  useUpdateReviewsProductDocumentsMutation,
  useCreateReviewsProductScreenshotMutation,
  useUpdateReviewsProductScreenshotsMutation,
  useDeleteReviewsProductScreenshotsMutation,
} from '@/gql/reviews'
import { useI18n } from 'vue-i18n'
import ContentCard from '@/ui/ContentCard.vue'
import ReviewsProductTabs from './../ReviewsProductTabs.vue'
import { useRouter } from 'vue-router'
import { reloadReviewsProductKey } from '@/symbols/reloadReviewsProductKey'
import { RouteName } from '@/router/types'
import { computed, inject, ref } from 'vue'
import ReviewsProductDocumentsEditForm from './ReviewsProductDocumentsEditForm.vue'
import type { ReviewsProductDocumentEditData } from '@/components/ReviewsManagement'
import { splitDocumentsForMutations } from '@/components/ReviewsManagement/Assets/documentsEditHelper'
import { showNotification } from '@/helpers/notificationHelper'
import LabelValue from '@/ui/LabelValue.vue'
import { splitScreenshotsForMutations } from './screenshotsEditHelper'
import type { ReviewsProductScreenshotEditData } from '../types'

const props = defineProps<{
  product: ProductData
}>()

const { t } = useI18n()
const router = useRouter()

const uploadProgress = ref(0)
const reloadReviewsProduct = inject(reloadReviewsProductKey) as () => void

const {
  mutate: mutateUpdateDocuments,
  loading: loadingUpdateDocuments,
  onError: onErrorUpdateDocuments,
} = useUpdateReviewsProductDocumentsMutation({
  clientId: 'reviews',
})

const {
  mutate: mutateCreateDocument,
  loading: loadingCreateDocument,
  onError: onErrorCreateDocument,
} = useCreateReviewsProductDocumentMutation({
  clientId: 'reviews',
  context: {
    fetchOptions: {
      useUpload: true,
      onProgress: (progress: ProgressEvent) => {
        uploadProgress.value = Math.round((progress.loaded / progress.total) * 100)
      },
    },
  },
})

const {
  mutate: mutateDeleteDocuments,
  loading: loadingDeleteDocuments,
  onError: onErrorDeleteDocuments,
} = useDeleteReviewsProductDocumentsMutation({
  clientId: 'reviews',
})

const {
  mutate: mutateUpdateScreenshots,
  loading: loadingUpdateScreenshots,
  onError: onErrorUpdateScreenshots,
} = useUpdateReviewsProductScreenshotsMutation({
  clientId: 'reviews',
})

const {
  mutate: mutateCreateScreenshot,
  loading: loadingCreateScreenshot,
  onError: onErrorCreateScreenshot,
} = useCreateReviewsProductScreenshotMutation({
  clientId: 'reviews',
  context: {
    fetchOptions: {
      useUpload: true,
      onProgress: (progress: ProgressEvent) => {
        uploadProgress.value = Math.round((progress.loaded / progress.total) * 100)
      },
    },
  },
})

const {
  mutate: mutateDeleteScreenshots,
  loading: loadingDeleteScreenshots,
  onError: onErrorDeleteScreenshots,
} = useDeleteReviewsProductScreenshotsMutation({
  clientId: 'reviews',
})

const loading = computed(
  () =>
    loadingUpdateScreenshots.value ||
    loadingCreateScreenshot.value ||
    loadingDeleteScreenshots.value ||
    loadingUpdateDocuments.value ||
    loadingCreateDocument.value ||
    loadingDeleteDocuments.value,
)

const editableDocuments = ref<ReviewsProductDocumentEditData[]>([])

const handleDocumentsUpdate = (updatedDocuments: ReviewsProductDocumentEditData[]) => {
  editableDocuments.value = updatedDocuments
}

const editableScreenshots = ref<ReviewsProductScreenshotEditData[]>([])

const handleScreenshotsUpdate = (updatedScreenshots: ReviewsProductScreenshotEditData[]) => {
  editableScreenshots.value = updatedScreenshots
}

async function onSubmit() {
  const { documentsToCreate, documentsToUpdate, documentsToDelete } = splitDocumentsForMutations(editableDocuments.value)
  const { screenshotsToCreate, screenshotsToUpdate, screenshotsToDelete } = splitScreenshotsForMutations(editableScreenshots.value)

  const documentsMutations: Promise<any>[] = []
  if (documentsToUpdate.length > 0) {
    documentsMutations.push(
      mutateUpdateDocuments({
        productId: props.product.id,
        documentAttributes: documentsToUpdate,
      }),
    )
  }
  if (documentsToCreate.length > 0) {
    documentsMutations.push(
      ...documentsToCreate.map((document) =>
        mutateCreateDocument({
          productId: props.product.id,
          documentAttributes: document,
        }),
      ),
    )
  }
  if (documentsToDelete.length > 0) {
    documentsMutations.push(
      mutateDeleteDocuments({
        productId: props.product.id,
        ids: documentsToDelete,
      }),
    )
  }

  const screenshotsMutations: Promise<any>[] = []
  if (screenshotsToUpdate.length > 0) {
    screenshotsMutations.push(
      mutateUpdateScreenshots({
        productId: props.product.id,
        screenshotAttributes: screenshotsToUpdate,
      }),
    )
  }
  if (screenshotsToCreate.length > 0) {
    screenshotsMutations.push(
      ...screenshotsToCreate.map((screenshot) =>
        mutateCreateScreenshot({
          productId: props.product.id,
          screenshotAttributes: screenshot,
        }),
      ),
    )
  }
  if (screenshotsToDelete.length > 0) {
    screenshotsMutations.push(
      mutateDeleteScreenshots({
        productId: props.product.id,
        ids: screenshotsToDelete,
      }),
    )
  }

  try {
    await Promise.all([...documentsMutations, ...screenshotsMutations])
    uploadProgress.value = 0
    reloadReviewsProduct()
    showNotification(t('Reviews.product.notifications.savedSuccessfully', { name: props.product.title }), 'success')
    await router.push({ name: RouteName.ReviewsProductViewAssets, params: { productId: props.product.id } })
  } catch (e) {
    uploadProgress.value = 0
  }
}

onErrorUpdateDocuments((error) => showError(error))
onErrorCreateDocument((error) => showError(error))
onErrorDeleteDocuments((error) => showError(error))
onErrorUpdateScreenshots((error) => showError(error))
onErrorCreateScreenshot((error) => showError(error))
onErrorDeleteScreenshots((error) => showError(error))

const showError = (error: any) => {
  uploadProgress.value = 0
  if (error) showNotification(error.message, 'error')
}
</script>
